<template>
  <div>
    <side-bg-container
      :title='sideBgContainerConfigs.title'
      :height='sideBgContainerConfigs.height'
      :bar-style='sideBgContainerConfigs.barStyle'>
      <div class='h-full flex flex-col lg:flex-row justify-start items-center gap-y-2 lg:gap-y-0 lg:gap-x-4 lg:max-w-5xl overflow-x-auto'>
        <main-important-date-card 
          v-for='(importantDate, index) in importantDates'
          :key='`importantDate-${index}-${importantDate.title}`'
          :important-date='importantDate'
          :main-card-width='mainCardWidth'
          :card-date-height='importantDate.cardDateHeight'
          :card-title-height='importantDate.cardTitleHeight'
          :card-title-text-size='importantDate.titleTextSize' />
      </div>
    </side-bg-container>
  </div>
</template>

<script>
import MainImportantDateCard from '@/components/MainImportantDateCard.vue'
import SideBgContainer       from '@/components/SideBgContainer.vue'

export default {
  name: 'MainImportantDates',
  components: {
    MainImportantDateCard,
    SideBgContainer,
  },
  props: {
    sideBgContainerConfigs: {
      type: Object,
      default: () => {
        return {
          title: 'Important Dates',
          height: '580px',
          barStyle: 'background-color: var(--eventMainColor); color: #FFF;'
        }
      }
    },
    importantDates: {
      type: Array,
      default: () => ([])
    },
    mainCardWidth: {
      type: String,
      default: '14rem',
    },
  },
}
</script>
