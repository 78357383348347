<template>
  <div 
    class='side-bg-container relative px-2 lg:px-0'
    :style='heightStyle'>
    <div 
      class='side-styled-bg'
      :class='isNarrowClass'
      :style='sideStyle'>
      <h1 class='lg:text-4xl text-2xl font-light'>{{title}}</h1>
    </div>
    <div class='side-bg-contents'>
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SideBgContainer',
  props: [
    'title',
    'height',
    'barStyle',
    'notFullBleed',
  ],
  computed: {
    sideStyle () {
      return `${this.barStyle}`
    },
    heightStyle () {
      return {
       '--sideScrollContainer': this.height
      }
    },
    isNarrowClass () {
      return (this.notFullBleed) ? 'narrow-style' : ''
    }
  }

}
</script>

<style lang='scss' scoped>
  .side-bg-container {
    @apply relative;
    height: auto;
  }

  .side-bg-contents {
    @apply relative;
    height: auto;
  }


  .side-styled-bg {
    @apply p-4;
    width: auto;
    height: auto;
    position: relative;
    top: auto;
  }

  .side-styled-bg h1 {
    position: relative;
    top: auto;
    right: auto;
  }


  @media (min-width: 1024px) { 
    .side-bg-container {
      @apply relative;
      height: var(--sideScrollContainer);
    }

   .side-bg-contents {
      @apply absolute right-0;
      height: var(--sideScrollContainer);
    }

    .side-styled-bg {
      height: var(--sideScrollContainer);
      @apply p-0;
      position: absolute;
      left: calc(40rem - 50vw); 
      width: calc(50vw - 10rem);
      top: 0;
    }

    .side-styled-bg.narrow-style {
      left: 0;
      width: 20rem;
    }

   .side-styled-bg h1 {
      position: absolute;
      top: 8.5rem; 
      right: 20rem;
      max-width: 10rem;
    }

    .side-styled-bg.narrow-style h1 {
      right: 7.5rem;
    }

  }
</style>
