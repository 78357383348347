<template>
  <div>
    <div 
      class='p-16 rounded-xl'
      style='color: #235099; background-color: #FFFFFF22'>
      <h1 
        class='text-4xl font-bold mb-4'
        style='line-height: 3rem;'>
        The 66th Annual Congress of the<br> 
        Korean Orthopaedic Association 2022
      </h1>
      <h2 class='font-semibold'>Hybrid Congress</h2>
      <h3 class='font-semibold'>2022.10.13-15 <span class='uppercase font-normal opacity-70'>(THU - SAT)</span></h3>
      <h3 class='font-semibold'>Swiss Grand Hotel Convention Center <span class='uppercase font-normal opacity-70'>(Seoul, South Korea)</span></h3>
      <h3 class='mt-8 uppercase font-normal opacity-70'>Hosted by</h3>
      <h4 class='font-semibold'>The Korean Orthopaedic Association</h4>
      <h3 class='mt-2 uppercase font-normal opacity-70'>Official Language</h3>
      <h4 class='font-semibold'>English</h4>
      <h3 class='mt-2 uppercase font-normal opacity-70'>Official Website</h3>
      <a href='https://2022.koa.or.kr' 
         target='_blank'
         class='font-semibold' >
        https://2022.koa.or.kr
      </a>
      <h3 class='mt-2 uppercase font-normal opacity-70'>Online Congress Website</h3>
      <a href='https://koa2022.webconcert.co.kr' 
         target='_blank'
         class='font-semibold' >
        https://koa2022.webconcert.co.kr
      </a>
    </div>
    <main-important-dates
      :important-dates='importantDates'
      :sidebar-bg-container-configs='sidebarConfigs'
      main-card-width='14rem' />
  </div>
</template>

<script>
import MainImportantDates from '@/components/MainImportantDates.vue'

export default {
  name: 'MeetingInformation',
  components: {
    MainImportantDates
  },
  data () {
    return {
      sidebarConfigs: {
        title: 'Important Dates',
        height: '580px',
        barStyle: 'linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%); color: #FFF;'
      },
      importantDates: [
        {
          monthString: 'August',
          dayString: '12',
          title: 'Completed Papers or Extended Abstracts Submission Deadline',
          description: ''
        },
        {
          monthString: 'August',
          dayString: '22',
          title: 'Presentation Schedule Release Date',
          description: ''
        },
        {
          monthString: 'September',
          dayString: '26',
          title: 'Offline & Online Pre-registration Deadline',
          description: ''
        },
        {
          monthString: 'September',
          dayString: '30',
          title: 'Presentation File Submission Deadline',
          description: ''
        },
        {
          monthString: 'October',
          dayString: '7',
          title: 'Online Only Pre-registration Deadline',
          description: ''
        }
      ],
    }
  },
}
</script>


