<template>
  <div class='main-card opacity-95 flex flex-row lg:flex-col'
    :class='hasLinkStyles'
    :style='mainCardStyle'
    @click='goToLink'>
    <div 
      class='card-date-box px-4 py-4 lg:p-8 w-36 flex-grow-0 flex-shrink-0 lg:w-full'
      style='background-color: #F2F3F7;'
      :style='importantDate.dateCustomCss'>
      <h1 class='text-2xl lg:text-4xl font-light'>{{ importantDate.dayString }}</h1>
      <h2 class='uppercase opacity-80 text-sm pb-8'>{{ importantDate.monthString }}</h2>
    </div>
    <div 
      v-html='importantDate.title'
      class='main-card-title-box p-4 lg:p-8 bg-white flex-grow whitespace-pre'
      :style='importantDate.titleCustomCss'></div>
  </div>
</template>

<script>
export default {
  name: 'MainImportantDateCard',
  props: {
    importantDate: {
      type: Object,
      required: true,
    },
    mainCardWidth: {
      type: String,
      default: '14rem',
    },
    cardDateHeight: {
      type: String,
      default: '9.75rem',
    },
    cardTitleHeight: {
      type: String,
      default: '12rem',
    },
    cardTitleTextSize: {
      type: String,
      default: '14px',
    },
  },
  computed: {
    mainCardStyle () {
      return {
        '--cardWidth': this.mainCardWidth,
        '--cardDateHeight': this.cardDateHeight,
        '--cardTitleHeight': this.cardTitleHeight,
        '--cardTitleTextSize': this.cardTitleTextSize,
      }
    },
    hasLinkStyles () {
      return this.importantDate.goToLinkUrl ? 'cursor-pointer hover:opacity-100 hover:shadow-md rounded-none hover:p-2' : ''
    },
  },
  methods: {
    goToLink () {
      if (this.importantDate.goToLinkUrl) {
        window.location = this.importantDate.goToLinkUrl
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  .main-card {
    width: 100%;
    height: auto;
  }

  .main-card-title-box,
  .card-date-box {
    height: 6rem;
    font-size: 1.2rem;
  }

  @media (min-width: 1024px) { 
    .main-card {
      width: var(--cardWidth);
      height: auto;
    }
    .card-date-box {
      height: var(--cardDateHeight);
    }
    .main-card-title-box {
      height: var(--cardTitleHeight);
      font-size: var(--cardTitleTextSize);
    }
  }

</style>
